import axios from 'axios';


const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
//Org
    orgs: null,
    addressBookOrg: null,
    addressBookOrgScheme: null,
//Org Address
    orgAddresses: null,
    orgAddressScheme: null,
//Org Urls
    orgUrls: null,
    orgUrlScheme: null,
//Org Publications
    orgPubs: null,
    orgPubScheme: null,
//Org Staff
    orgMainContactsList: null,
    addressBookOrgStaffList: null,
    addressBookOrgDeletedStaffList: null,
//Org Projects
    addressBookOrgProjects: null,
//Org Circulations
    orgCirculations: null,
//User
    addressBookUser: null,
    orgUserScheme: null,
//User Privileges
    userRoles: null,
    userRolesScheme: null,
//User Projects
    userProjects: null,
//User Subscription
    userTopicSubscriptions: null,
    userProjectSubscriptions: null,
//User Preferences
    userEditPreferenceSettingsScheme: null,
//ICRT Staff
    teamList: null,
//Upload
    uploadProgress: 0,
}

const getters = {
//Org
    orgs: state => state.orgs,
    addressBookOrg: state => state.addressBookOrg,
    addressBookOrgScheme: state => state.addressBookOrgScheme,
    //Address
    orgAddresses: state => state.orgAddresses,
    orgAddressScheme: state => state.orgAddressScheme,
    //URLs
    orgUrls: state => state.orgUrls,
    orgUrlScheme: state => state.orgUrlScheme,
    //Pubs
    orgPubs: state => state.orgPubs,
    orgPubScheme: state => state.orgPubScheme,
    // Circulations
    orgCirculations: state => state.orgCirculations,
    //Main Contacts
    orgMainContactsList: state => state.orgMainContactsList,
    addressBookOrgStaffList: state => state.addressBookOrgStaffList,
    addressBookOrgDeletedStaffList: state => state.addressBookOrgDeletedStaffList,
    addressBookOrgProjects: state => state.addressBookOrgProjects,
//User
    addressBookUser: state => state.addressBookUser,
    orgUserScheme: state => state.orgUserScheme,
    userRoles: state => state.userRoles,
    userRolesScheme: state => state.userRolesScheme,
    userProjects: state => state.userProjects,
    userTopicSubscriptions: state => state.userTopicSubscriptions,
    userProjectSubscriptions: state => state.userProjectSubscriptions,
    userEditPreferenceSettingsScheme: state => state.userEditPreferenceSettingsScheme,
    teamList: state => state.teamList,
// Upload
    uploadProgress: state => state.uploadProgress,
}

const mutations = {

//Update Logo/ Photo

    setOrgLogo: (state, logo) => {
        state.addressBookOrg.logo = logo
    },

    setUserPhoto: (state, photo) => {
        state.addressBookUser.photo = photo
    },

    setOrgs: (state, orgs ) => state.orgs = orgs,
//Org
    setAddressBookOrg: (state, data ) => state.addressBookOrg = data,
    setAddressBookOrgScheme: (state, data) => state.addressBookOrgScheme = data,
    setAddressBookNewOrg: (state, data) => {
        let newArray = state.orgs
        newArray.unshift(data)
        state.orgs = null
        state.orgs = newArray
    },
    setAddressBookUpdatedOrg: (state, data) => {

        if(Array.isArray(state.orgs)) {
            let orgObjectIndex = state.orgs.findIndex(( org => org.id ===  data.id));
            state.orgs[orgObjectIndex] = data;
            state.orgs = [...new Set(state.orgs)];
        }
        
        state.addressBookOrg = {};
        Object.assign(state.addressBookOrg, data);
    },
    setAddressBookDeletedOrg: (state, data) => {
        let newArray = state.orgs.filter(org => org.id !== data.id)
        state.orgs = null
        state.orgs = newArray
    },
//Org Addresses
    setOrgAddresses: (state, data ) => state.orgAddresses = data,
    setOrgAddressScheme: (state, data ) => state.orgAddressScheme = data,
    setNewOrgAddress: (state, data) => {
        let newArray = state.orgAddresses
        newArray.push(data)
        state.orgAddresses = null
        state.orgAddresses = newArray
    },

    setUpdatedOrgAddress: (state, data) => {
        let newArray = state.orgAddresses
        let addressObjectIndex = newArray.findIndex((address => address.id ===  data.id));
        newArray[addressObjectIndex] = data
        state.orgAddresses = null
        state.orgAddresses = [...newArray]
    },
    setDeletedOrgAddress: (state, data) => {
        let newArray = state.orgAddresses.filter(address => address.id !== data.id)
        state.orgAddresses = null
        state.orgAddresses = newArray
    },
//Org URLs
    setOrgUrls: (state, data ) => state.orgUrls = data,
    setOrgUrlScheme: (state, data ) => state.orgUrlScheme = data,
    setNewOrgUrl: (state, data) => {
        let newArray = state.orgUrls
        newArray.push(data)
        state.orgUrls = null
        state.orgUrls = newArray
    },
    setUpdatedOrgUrl: (state, data) => {
        let newArray = state.orgUrls
        let urlObjectIndex = newArray.findIndex((url => url.id ===  data.id));
        newArray[urlObjectIndex] = data
        state.orgUrls = null
        state.orgUrls = [...newArray]
    },
    setDeletedOrgUrl: (state, data) => {
        let newArray = state.orgUrls.filter(url => url.id !== data.id)
        state.orgUrls = null
        state.orgUrls = newArray
    },

//Org Publications
    setOrgPubs: (state, data ) => state.orgPubs = data,
    setOrgPubScheme: (state, data ) => state.orgPubScheme = data,
    setNewOrgPub: (state, data) => {
        let newArray = state.orgPubs
        newArray.push(data)
        state.orgPubs = null
        state.orgPubs = newArray
    },
    setUpdatedOrgPub: (state, data) => {
        let newArray = state.orgPubs
        let pubObjectIndex = newArray.findIndex((url => url.id ===  data.id));
        newArray[pubObjectIndex] = data
        state.orgPubs = null
        state.orgPubs = [...newArray]
    },
    setDeletedOrgPub: (state, data) => {
        let newArray = state.orgPubs.filter(url => url.id !== data.id)
        state.orgPubs = null
        state.orgPubs = newArray
    },
// Org Circulations
    setOrgCirculations: (state, circulations) => state.orgCirculations = circulations,
//Org Main Contacts
    setOrgMainContactsList:  (state, data) => state.orgMainContactsList = data,
//Org Users
    setAddressBookOrgStaffList: (state, data) => state.addressBookOrgStaffList = data,
    setAddressBookOrgDeletedStaffList: (state, data) => state.addressBookOrgDeletedStaffList = data,
//OrgProjects
    setAddressBookOrgProjects: (state, data ) => state.addressBookOrgProjects = data,
//User
    setAddressBookUser: (state, data ) =>  state.addressBookUser = data,
    setOrgUserScheme: (state, data ) => state.orgUserScheme = data,

    setNewOrgUser: (state, data) => {
        if(!state.addressBookOrgStaffList) return

        let newArray = state.addressBookOrgStaffList
        newArray.push(data)
        state.addressBookOrgStaffList = null
        state.addressBookOrgStaffList = newArray
    },

    setUpdatedOrgUser: (state, data) => {
        state.addressBookUser = null
        state.addressBookUser = data

        if(state.addressBookOrgStaffList){
            let newArray = state.addressBookOrgStaffList
            let userObjectIndex = newArray.findIndex((user => user.id ===  data.id));
            newArray[userObjectIndex] = data
            state.addressBookOrgStaffList = null
            state.addressBookOrgStaffList = [...newArray]
        }

        if(state.orgMainContactsList) {
            let newArray = state.orgMainContactsList
            let userObjectIndex = newArray.findIndex((user => user.id ===  data.id));
            newArray[userObjectIndex] = data
            state.orgMainContactsList = null
            state.orgMainContactsList = [...newArray]
        }

    },
    setDeletedOrgUser: (state, data) => {
        if(state.addressBookOrgStaffList){
            let newArray = state.addressBookOrgStaffList.filter(user => user.id !== data.id)
            state.addressBookOrgStaffList = null
            state.addressBookOrgStaffList = newArray
        }

        if(state.orgMainContactsList){
            let newArray = state.orgMainContactsList.filter(user => user.id !== data.id)
            state.orgMainContactsList = null
            state.orgMainContactsList = newArray
        }
    },

    setNewUserInTerminated(state, data) {
        if(!state.addressBookOrgDeletedStaffList) return

        let addressBookOrgDeletedStaffListCopy = state.addressBookOrgDeletedStaffList
        addressBookOrgDeletedStaffListCopy.push(data)
        state.addressBookOrgDeletedStaffList = null
        state.addressBookOrgDeletedStaffList = addressBookOrgDeletedStaffListCopy

    },

    setTerminatedOrgUser: (state, id) => {
        let newArray = state.addressBookOrgDeletedStaffList.filter(user => user.id !== id)
        state.addressBookOrgDeletedStaffList = null
        state.addressBookOrgDeletedStaffList = newArray
    },

//User Privileges
    setUserRoles: (state, data) => state.userRoles = data,
    setUserRolesScheme: (state, data) => state.userRolesScheme = data,

    setNewUserRole: (state, data) => {
        let newUserRoles = state.userRoles
        newUserRoles.push(data)
        state.userRoles = null
        state.userRoles = newUserRoles
    },
    setUserRoleChange: (state, data) => {
        let newRoles = state.userRoles
        newRoles = data
        state.userRoles = null
        state.userRoles = newRoles
    },
//User Projects
    setUserProjects: (state, data) => state.userProjects = data,
//User Subscription
    setUserTopicSubscriptions: (state, data) => state.userTopicSubscriptions = data,
    setUserProjectSubscriptions: (state, data) => state.userProjectSubscriptions = data,
//User Activated/ DeActivated
    setActivateDeactivateUser:  (state, data) => {
        let updatedUser = state.addressBookUser
        updatedUser.ldap_entry = data.ldap_entry
        state.addressBookUser = null
        state.addressBookUser = updatedUser

    },
    setDeActivatedUser:  (state, data) => {
        let updatedUser = state.addressBookUser
        updatedUser.ldap_entry = data.ldap_entry
        updatedUser.roles = data.roles
        state.addressBookUser = null
        state.addressBookUser = updatedUser
    },
    setUserEditPreferenceSettingsScheme: (state, data) => state.userEditPreferenceSettingsScheme = data,
    setTeamList: (state, data) => state.teamList = data,
    setUploadProgress: (state, percentage) => state.uploadProgress = percentage,
}

const actions = {
//Orgs
   async fetchOrgs({commit}) {
        try {
            const res = await  axios.get('/addressbook.json');
            if(res.data.error) throw res.data.message
            commit('setOrgs', res.data);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async fetchInactiveOrgs({commit}) {
        try {
            const res = await axios.get('/addressbook.json?status=false');
            if(res.data.error) throw res.data.message
            commit('setOrgs', res.data);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

//Organisation
    fetchOrganisation({commit}, id) {
        axios.get(`/addressbook/${id}.json`).then(res => {
            commit('setAddressBookOrg', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

//Fetch NEW Organisation new.json
    fetchOrgNewScheme({commit}) {
        axios.get(`/orgs/new.json`).then(res => {
            commit('setAddressBookOrgScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

//POST NEW Organisation
   async createOrganisation({commit}, org) {
       try {
           const res = await  axios.post(`/orgs.json`, org , {headers: {'Content-Type': 'application/json'}})

           if(res.data.error) throw res.data.message
           commit('setAlertResponse', { type: 'successful', action: 'created', text: 'a new Organisation', data: res.data});
           commit('setAddressBookNewOrg', res.data);
           return Promise.resolve(res.data);

       } catch(error) {
           commit('setAlertResponse', { type: 'error', action: 'create', text: error, timeout: 8000});
           return Promise.reject(error)
       }
    },

//Fetch Organisation Edit.json
    async fetchOrgEditScheme({commit}, id){

        try {
            const res = await axios.get(`/orgs/${id}/edit.json`);

            if(res.data.error) throw res.data.message
            commit('setAddressBookOrgScheme', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'update', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

//PUT Update Organisation
    async updateOrganisation({commit}, {id, updatedOrg}){

        try {
            const res = await axios.put(`/orgs/${id}.json`, updatedOrg, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'Organisation'});
            commit('setAddressBookUpdatedOrg', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'update', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

//Delete Organisation
    async deleteOrganisation({commit}, id){
        try {
            const res = await   axios.delete(`/orgs/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            });

            if(res.data.error) return commit('setAlertResponse', { type: 'error', text: res.data.message})
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'Organisation'});
            commit('setAddressBookDeletedOrg', res.data);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

//Organisation ADDRESSES

    fetchOrgAddresses({commit}, id){
        axios.get(`/orgs/${id}/org_addresses.json`).then(res => {
            commit('setOrgAddresses', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchNewOrgAddressScheme({commit}, id){
        axios.get(`/orgs/${id}/org_addresses/new.json`).then(res => {
            commit('setOrgAddressScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async addNewOrgAddress({commit}, newOrgAddress) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                org_address_params: newOrgAddress,
            };

            const res = await  axios.post(`/org_addresses.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'added', text: 'a new Organisation Address'});
            commit('setNewOrgAddress', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    fetchEditOrgAddressScheme({commit}, id){
        axios.get(`/org_addresses/${id}/edit.json`).then(res => {
            commit('setOrgAddressScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateOrgAddress({commit}, {id, updatedOrgAddress}){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                org_address_params: updatedOrgAddress,
                id: id,
            };

            const res = await axios.put(`/org_addresses/${id}.json	`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'Organisation Address'});
            commit('setUpdatedOrgAddress', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'update', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async deleteOrgAddress({commit}, id){
        try {
            const res = await axios.delete(`/org_addresses/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'Organisation Address'});
            commit('setDeletedOrgAddress', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },


//Organisation URLs

    fetchOrgUrls({commit}, id){
        axios.get(`/orgs/${id}/org_urls.json `).then(res => {
            commit('setOrgUrls', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchNewOrgUrlScheme({commit}, id){
        axios.get(`/orgs/${id}/org_urls/new.json`).then(res => {
            commit('setOrgUrlScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createOrgUrl({commit}, newOrgUrl) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                org_url: newOrgUrl,
            };

            const res = await axios.post(`/org_urls.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'added', text: 'a new Organisation URL'});
            commit('setNewOrgUrl', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    fetchEditOrgUrlScheme({commit}, id){
        axios.get(`/org_urls/${id}/edit.json`).then(res => {
            commit('setOrgUrlScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateOrgUrl({commit}, {id, updatedOrgUrl}){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                org_url: updatedOrgUrl,
                id: id,
            };

            const res = await axios.put(`/org_urls/${id}.json	`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'Organisation URL'});
            commit('setUpdatedOrgUrl', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'update', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async deleteOrgUrl({commit}, id){
        try {
            const res = await axios.delete(`/org_urls/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'Organisation URL'});
            commit('setDeletedOrgUrl', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },


//Organisation Publications

    fetchOrgPubs({commit}, id){
        axios.get(`/orgs/${id}/org_pubs.json`).then(res => {
            commit('setOrgPubs', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchNewOrgPubScheme({commit}, id){
        axios.get(`/orgs/${id}/org_pubs/new.json`).then(res => {
            commit('setOrgPubScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createOrgPub({commit}, newOrgPub) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                org_pub: newOrgPub,
            };

            const res = await axios.post(`/org_pubs.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'added', text: 'a new Publication'});
            commit('setNewOrgPub', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    fetchEditOrgPubScheme({commit}, id){
        axios.get(`/org_pubs/${id}/edit.json`).then(res => {
            commit('setOrgPubScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateOrgPub({commit}, {id, updatedOrgPub}){
        try {

            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                org_pub: updatedOrgPub,
                id: id,
            };

            const res = await  axios.put(`/org_pubs/${id}.json	`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'Publication'});
            commit('setUpdatedOrgPub', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'update', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async deleteOrgPub({commit}, id){
        try {

            const res = await axios.delete(`/org_pubs/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'Publication'});
            commit('setDeletedOrgPub', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

//Organisation Main Contact

    fetchOrgMainContactsList({commit}, id){
        axios.get(`/addressbook/${id}/main_contacts.json`).then(res => {
            commit('setOrgMainContactsList', res.data);
        }).catch(error => {
            commit('setError', error.toString());
    })
},

//Organisation Staff
    //Active
    fetchAddressBookOrgStaffList({commit}, id){
        axios.get(`/addressbook/${id}/show_staff_by_org.json`).then(res => {
            commit('setAddressBookOrgStaffList', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    //Deleted
    fetchAddressBookOrgDeletedStaffList({commit}, id){
        axios.get(`/addressbook/${id}/show_non_active_staff_by_org.json`).then(res => {
            commit('setAddressBookOrgDeletedStaffList', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

//Organisation Projects
    fetchAddressBookOrgProjects({commit}, id){
        axios.get(`/addressbook/${id}/org_projects.json`).then(res => {
            commit('setAddressBookOrgProjects', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchArchivedAddressBookOrgProjects({commit}, id){
        axios.get(`/addressbook/${id}/org_projects.json?archive`).then(res => {
            commit('setAddressBookOrgProjects', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

// Org Circulations
    fetchOrgCirculations({commit}, org_id){
        axios.get(`/org_circulations/${org_id}.json`).then(res => {
            commit('setOrgCirculations', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async deleteOrgCirculation({commit, dispatch}, {id, org_id}) {
        try {

            const res = await axios.delete(`/org_circulations/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message;
            dispatch('fetchOrgCirculations', org_id);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

// * * * * * * * * * * * * * * * * * * * * * * * * * * *

//USER

    async fetchAddressBookUser({commit}, id) {
        try {
            const res = await axios.get(`/users/${id}.json`)
            commit('setAddressBookUser', res.data);
            return res.data

        } catch(error) {
            commit('setError', error);
        }
    },

    fetchNewUserScheme({commit}, id){
        axios.get(`/orgs/${id}/users/new.json`).then(res => {
            commit('setOrgUserScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchAdminNewUserScheme({commit}, id){
        axios.get(`/users/new.json `).then(res => {
            commit('setOrgUserScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createUser({commit}, newOrgUser) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                ...newOrgUser
            };

            const res = await axios.post(`/users.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) {
                Object.keys(res.data.message).forEach(error => {
                    commit('setAlertResponse', { type: 'error', action: 'create', text: `${error} ` +  res.data.message[error], timeout: 8000});
                })
                return Promise.reject()
            }
            commit('setAlertResponse', { type: 'successful', action: 'created', text: 'User'});
            commit('setUpdatedOrgUser', res.data);
            return Promise.resolve(res.data);
        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    fetchEditUserScheme({commit}, id){
        axios.get(`/users/${id}/edit.json`).then(res => {
            commit('setOrgUserScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateUser({commit}, {id, updatedOrgUser}){

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                ...updatedOrgUser
            };

            const res = await axios.put(`/users/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'User'});
            commit('setUpdatedOrgUser', res.data);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'update', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async deleteUser({commit}, id){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                user_id: id,
                user: state.addressBookUser
            };

            const res = await axios.post(`/users/suspend.json`, params ,{
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                }
            });

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', { type: 'successful', action: 'deleted', text: 'User', pageType: 'deletedUser', data: res.data});
            commit('setDeletedOrgUser', res.data);
            commit('setNewUserInTerminated', res.data)
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async terminateOrgUser({commit}, id){

        try {
            const res = await axios.delete(`/users/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                }
            })

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'terminated', text: 'User'});
            commit('setTerminatedOrgUser', id);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },


    //Update User Password

    async updateUserPassword({commit}, user){
        const authentication = {
            charset: 'UTF8',
            authenticity_token: state.token,
            authentication: user
        };

       const res = await axios.put(`/change/password.json`, authentication,
           { headers: {'Content-Type': 'application/json'}}).catch(error => {
            commit('setError', error.toString());
        });

        return res.data
    },



    async uploadAvatar({commit}, formData) {
      try {
          const res = await axios.post('/bucket.json', formData, {
              headers: {
                  "Content-Type": "multipart/form-data",
                  'X-CSRF-Token': state.token,
              },
              onUploadProgress: (event) => {
                  const percentage = Math.round((100 * event.loaded) / event.total);
                  commit('setUploadProgress', percentage);
              },
          });

          commit('setUploadProgress', 0);

          return res.data.url

      } catch(error) {
          commit('setError', error);
      }
    },


    async deleteAvatar({commit}, {model, id}) {
        try {
            const res = await axios.delete(`/bucket/${model}/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                }
            });

            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)

        }
    },


//User Privileges

    fetchUserRoles({commit}, id){
        axios.get(`/user_roles/${id}.json`).then(res => {
            commit('setUserRoles', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },


    fetchUserRolesScheme({commit}, id){
        axios.get(`/users/${id}/user_roles/new.json`).then(res => {
            commit('setUserRolesScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createUserRole({commit}, user_privilege) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                user_role: user_privilege
            };

            const res = await axios.post(`/user_roles.json`,params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'added', text: 'User Privilege'});
            commit('setNewUserRole', res.data)
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'create', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async removeUserRole({commit, dispatch}, {user_id, user_role_id}){
        try {
            const res = await axios.delete(`/user_roles/${user_role_id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                }
            })

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'User Privilege'});
            dispatch('fetchUserRoles', user_id);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },


//User Projects

    async fetchUserProjects({commit}, id){
        try {
            const res = await axios.get(`/users/${id}/user_projects.json`)
            if(res.data.error) throw res.data.message
            commit('setUserProjects', res.data);
            return Promise.resolve();
        } catch(error){
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async fetchArchivedUserProjects({commit}, id){
        try {
            const res = await axios.get(`/users/${id}/user_projects.json?archive`)
            if(res.data.error) throw res.data.message
            commit('setUserProjects', res.data);
            return Promise.resolve();
        } catch(error){
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },



//User Subscriptions

    fetchUserTopicSubscriptions({commit}, id) {
        axios.get(`/users/${id}/topics_subscription.json`).then(res => {
            commit('setUserTopicSubscriptions', res.data.subscribed);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async subscribeTopicUserProfile({commit, dispatch}, {id, topic_id, type}) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                topic_id: topic_id
            };

            const res = await axios.post(`/users/${id}/subs_topic_from_profile.json`,params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: `${type}`, text: 'Topic'});
            dispatch('fetchUserTopicSubscriptions', id)
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: `${type}`, text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },



    async fetchUserProjectSubscriptions({commit}, id) {
        try {
            const res = await axios.get(`/users/${id}/projects_subscription.json`)
            if(res.data.error) throw res.data.message;
            commit('setUserProjectSubscriptions', res.data);
            return Promise.resolve(res.data);

        } catch(error) {
            return Promise.reject(error)
        }
    },


    async subscribeProjectUserProfile({commit}, {id, project_id}) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                project_id: project_id
            };


            const res = await  axios.post(`/users/${id}/subs_project_from_profile.json`,params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'unsubscribed', text: 'Project'});
            commit('setUserProjectSubscriptions', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'project subscription', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

//Activate User
    async activateUser({commit}, id){
        try {
            const res = await axios.get(`/users/${id}/activate.json`)

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'activated', text: 'User Account'});
            commit('setActivateDeactivateUser', res.data);
            commit('setUserRoleChange', res.data.roles);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'activate', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

//DeActivate User
   async deActivateUser({commit}, id){

        try {
            const res = await axios.get(`/users/${id}/deactivate.json`)

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'deactivated', text: 'User Account'});
            commit('setActivateDeactivateUser', res.data);
            commit('setUserRoleChange', res.data.roles);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'deactivate', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

//Reset User Password
    async resetUserPassword({commit}, id){
        try {
            const res = await axios.get(`/users/${id}/reset.json`)

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'reset', text: 'User Password'});
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'reset password', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

//User Settings/ Preferences
    fetchUserEditPreferenceSettingsScheme({commit}, id){
        axios.get(`/settings/${id}/edit.json`).then(res => {
            commit('setUserEditPreferenceSettingsScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateUserPreferencesSettings({commit}, {id, updatedUserPreferences}){

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                id: id,
                setting: updatedUserPreferences
            };

            const res = await axios.put(`/settings/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'saved', text: 'changes'});
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'update preferences', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },
    async fetchTeamList({commit}){
        try {
            const res = await axios.get(`/icrt_staff.json`)
            if(res.data.error) throw res.data.message
            commit('setTeamList', res.data);
            return Promise.resolve();
        } catch(error){
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    ghostUser({commit}, id){
       return new Promise(async (resolve, reject) => {
           try {
               const params = {
                   charset: 'UTF8',
                   authenticity_token: state.token,
               };
               await axios.post(`/sessions/${id}/ghost.json`, params, {headers: {'Content-Type': 'application/json'}});
               resolve();
           } catch (error) {
               commit('setError', error.toString());
               reject(error);
           }
       });
    },


}

export default {
    state,
    getters,
    mutations,
    actions,
}
